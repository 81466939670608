import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import Layout from '../layouts/SimplePage';
import './404.scss';


const Error404Page = () => (
  <Layout
    pageWrapperClassName="error-page-404"
    headerClassName="header_white-bg"
  >

    <Helmet
      title="Error 404 - Page not found"
    />

    <section className="page404">
      <div className="page404__container container">
        <div className="page404__error-code">
          404
        </div>
        <h1 className="page404__heading">
          Page not found
        </h1>
        <p className="page404__go-home">
          Go to
          {' '}
          <Link
            to="/"
            className="page404__go-home__link"
          >
            home page →
          </Link>
        </p>
      </div>
    </section>

  </Layout>
);

export default Error404Page;
